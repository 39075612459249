import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { isSafari } from "react-device-detect";
import useAuth from "../hooks/useAuth";
import { firebaseConfig, instanceId } from "../utils/apis";

// ----------------------------------------------------------------------

export default function FirebaseInit() {
  const { isAuthenticated, saveToken, logout, initialize, user } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    getPerformance(app);
    if (!isSafari) {
      const beamsClient = new PusherPushNotifications.Client({ instanceId });
      if (isAuthenticated && "Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            beamsClient
              .start()
              .then(() => {
                const userId = (user && user.user && user.user.uid) || "";
                beamsClient.addDeviceInterest("general");
                if (userId) {
                  beamsClient.addDeviceInterest(userId);
                }
              })
              .then(() => console.log("Successfully registered and subscribed!"))
              .catch(console.error);
          }
        });
      }
    }
  }, [
    isAuthenticated,
    saveToken,
    enqueueSnackbar,
    closeSnackbar,
    logout,
    navigate,
    initialize,
    user,
  ]);

  return null;
}
